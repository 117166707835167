import React, { useContext } from 'react'

import { AdminContext } from './../../../../Contexts/AdminContext'

import AdminListCard from './AdminListCard'

export default function AdminLotContainer() {
let {
    adminVisibleLotList,
    filterLocation,
    // reloading,
    // endReload
} = useContext(AdminContext)

    let soldCheck

    if (filterLocation === 'sold') {
        soldCheck = true
    } else {
        soldCheck = false
    }

let adminContainerContent = adminVisibleLotList.map(lot => {
    return (
        <AdminListCard 
            key={lot.lotId}
            lotId={lot.lotId}
            location={lot.location}
            lotName={lot.lotName}
            price={lot.price}
            section={lot.section}
            otherLocation={lot.otherLocation}
            spaces={lot.spaces}
            lotNumber={lot.lotNumber}
            soldCheck={soldCheck}
        />
    )
})

    return (
        <div className='container-d-lots'>
            {adminContainerContent}
        </div>
    )
} 