import React, {useContext} from 'react'
import AddLotHeader from './AddLotHeader.js'
import AdminLotList from './LotList/AdminLotList'
import AddingNewLot from './Components/AddingNewLot'

import IndividualLotCard from './Components/IndividualLotCard'
import { AdminContext } from './../../../Contexts/AdminContext'

export default function AdminLotControl() {
    
    const { viewingIndividualLot } = useContext(AdminContext)

    let inidividualCardContent
    viewingIndividualLot
        ? inidividualCardContent = ( <IndividualLotCard /> )
        : inidividualCardContent = ( <div></div> )

    return (
        <div className='new-admin-d-lot-container'>
            <AddLotHeader />
            <AdminLotList />
            <AddingNewLot />
            {inidividualCardContent}
        </div>
    )
}