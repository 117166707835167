import React, { useContext } from 'react'

import { AdminContext } from './../../../../Contexts/AdminContext'

export default function AdminDetailsHeader() {
    let {
        filterLocation
    } = useContext(AdminContext)

    if (filterLocation === 'Sharon' || filterLocation === 'Interfaith' || filterLocation === 'Boylston') {
        return (
            <div className='context-d-details-header'>
                <div className="container-context-item">
                    Section
                </div>
                <div className="container-context-item">
                    Lot Number
                </div>
                <div className="container-context-item">
                    Spaces
                </div>
                <div className="container-context-item">
                    Price
                </div>
            </div> 
        )
    } else if (filterLocation === 'Knollwood') {
        return (
            <div className='context-d-details-header'>
                <div className="container-context-item2">
                    Lot Name
                </div>
                <div className="container-context-item">
                    Lot Number
                </div>
                <div className="container-context-item">
                    Spaces
                </div>
                <div className="container-context-item">
                    Price
                </div>
            </div> 
        )
    } else if (filterLocation === 'Estate' || filterLocation === 'Other') {
        return (
            <div className='context-d-details-header'>
                <div className="container-context-item2">
                    Location
                </div>
                <div className="container-context-item">
                    Spaces
                </div>
                <div className="container-context-item">
                    Price
                </div>
        </div>
        ) 
    } else if (filterLocation === 'Shara') {
        return (
            <div className='context-d-details-header'>
                <div className="container-context-item">
                    Spaces
                </div>
                <div className="container-context-item">
                    Price
                </div>
        </div>
        ) 
    } else if (filterLocation === 'sold') {
        return (
            <div className='context-d-details-header-sold'>
                <div className="container-context-item2">
                    Location
                </div>
                <div className="container-context-item2">
                    Section
                </div>
                <div className="container-context-item2">
                    Lot Name
                </div>
                <div className="container-context-item">
                    Spaces
                </div>
                <div className="container-context-item">
                    Price
                </div>
            </div>
        ) 
    }
}