import React, { useContext } from 'react'

import IconButton from '@material-ui/core/IconButton'
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone'

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

import AddAPhotoTwoToneIcon from '@material-ui/icons/AddAPhotoTwoTone'
import Button from '@material-ui/core/Button'

import { AdminContext } from './../../../../../Contexts/AdminContext'

export default function LotCardPicture() {
    let {
        editingCard,
        originalPath,
        setOriginalPath,
        setImageNumber,
        lotIsSold,
        currentlySelling,
        updatingCard,
        currentlyDeleting,
        successfullyDeletedImage,
        deletingImage,
        setImageUrl,
        otherLocation,
        lotName,
        location,
        lotNumber,
        uploadingImage,
        section,
        uploadingLotImage,
        uploadImageSuccess,
        lotId,
        imageUrl
    } = useContext(AdminContext)

    let cardImageUrl, changingOpacity, deleteImageButton, deleteDisabledCheck

    function deleteLotImage() {
        deletingImage()
        firebase.storage().ref(originalPath)
        .delete()
        .then(() => {
            setTimeout(() => {
                successfullyDeletedImage()
            }, 1000)
        })
        .catch(err => {
            console.error(err.code)
        })
    }

    const handleImageUpload = async (event) => {
        try {
            uploadingImage()

        let image
        if (!lotIsSold) {
            if (location === 'Sharon' || location === 'Interfaith' || location === 'Boylston') {
                image = event.target.files[0]
                firebase.storage().ref(`/Images/${location}/${section}-${lotNumber}`)
                .put(image)
                .then(() => {
                    firebase.storage().ref(`/Images/${location}/${section}-${lotNumber}`)
                    .getDownloadURL()
                    .then(url => {
                        setImageUrl(url)
                        let lotUrl = {
                            imageUrl: url
                        }
                        let path = {
                            originalPath: `/Images/${location}/${section}-${lotNumber}`
                        }
                        firebase.firestore().collection(location).doc(lotId)
                        .update(lotUrl)
                        .then(() => {
                            firebase.firestore().collection(location).doc(lotId)
                        .update(path)
                        .then(() => {
                            setOriginalPath(path.originalPath)
                        })
                        })
                    })
                })
                .then(() => {
                    uploadImageSuccess()
                })
                .catch(err => {
                    console.error(err.code)
                })
            } else if (location === 'Knollwood') {
                image = event.target.files[0]
                firebase.storage().ref(`/Images/${location}/${lotName}-${lotNumber}`)
                .put(image)
                .then(() => {
                    firebase.storage().ref(`/Images/${location}/${lotName}-${lotNumber}`)
                    .getDownloadURL()
                    .then(url => {
                        setImageUrl(url)
                        let lotUrl = {
                            imageUrl: url
                        }
                        let path = {
                            originalPath: `/Images/${location}/${lotName}-${lotNumber}`
                        }
                        firebase.firestore().collection(location).doc(lotId)
                        .update(lotUrl)
                        .then(() => {
                            firebase.firestore().collection(location).doc(lotId)
                        .update(path)
                        .then(() => {
                            setOriginalPath(path.originalPath)
                        })
                        })
                    })
                })
                .then(() => {
                    uploadImageSuccess()
                })
                .catch(err => {
                    console.error(err.code)
                })
            } else if (location === 'Estate' || location === 'Other') {
                let randomNumber = Math.floor(Math.random() * 1000)
                image = event.target.files[0]
                firebase.storage().ref(`/Images/${location}/${otherLocation}-${randomNumber}`)
                .put(image)
                .then(() => {
                    firebase.storage().ref(`/Images/${location}/${otherLocation}-${randomNumber}`)
                    .getDownloadURL()
                    .then(url => {
                        setImageUrl(url)
                        setImageNumber(randomNumber)
                        let lotUrl = {
                            imageUrl: url
                        }
                        let imageNumber = {
                            imageNumber: randomNumber
                        }
                        let path = {
                            originalPath: `/Images/${location}/${otherLocation}-${randomNumber}`
                        }
                        firebase.firestore().collection(location).doc(lotId)
                        .update(lotUrl)
                        .then(() => {
                            firebase.firestore().collection(location).doc(lotId)
                            .update(imageNumber)
                            .then(() => {
                                firebase.firestore().collection(location).doc(lotId)
                            .update(path)
                            .then(() => {
                                setOriginalPath(path.originalPath)
                            })
                            })
                        })
                    })
                })
                .then(() => {
                    uploadImageSuccess()
                })
                .catch(err => {
                    console.error(err.code)
                })
            } else if (location === 'Shara') {
                image = event.target.files[0]
                firebase.storage().ref(`/Images/Shara/SharaImage`)
                .put(image)
                .then(() => {
                    firebase.storage().ref(`/Images/Shara/SharaImage`)
                    .getDownloadURL()
                    .then(url => {
                        setImageUrl(url)
                        let lotUrl = {
                            imageUrl: url
                        }
                        let path = {
                            originalPath: `/Images/Shara/SharaImage`
                        }
                        firebase.firestore().collection(location).doc(lotId)
                        .update(lotUrl)
                        .then(() => {
                            firebase.firestore().collection(location).doc(lotId)
                        .update(path)
                        .then(() => {
                            setOriginalPath(path.originalPath)
                        })
                        })
                    })
                })
                .then(() => {
                    uploadImageSuccess()
                })
                .catch(err => {
                    console.error(err.code)
                })
            }
        } else if (lotIsSold) {
            if (location === 'Sharon' || location === 'Interfaith' || location === 'Boylston') {

                image = event.target.files[0]
                firebase.storage().ref(`/Images/${location}/${section}-${lotNumber}`)
                .put(image)
                .then(() => {
                    firebase.storage().ref(`/Images/${location}/${section}-${lotNumber}`)
                    .getDownloadURL()
                    .then(url => {
                        setImageUrl(url)
                        let lotUrl = {
                            imageUrl: url
                        }
                        let path = {
                            originalPath: `/Images/${location}/${section}-${lotNumber}`
                        }
                        firebase.firestore().collection('sold').doc(lotId)
                        .update(lotUrl)
                        .then(() => {
                            firebase.firestore().collection(location).doc(lotId)
                            .update(path)
                            .then(() => {
                                setOriginalPath(path.originalPath)
                            })
                        })
                    })
                })
                .then(() => {
                    uploadImageSuccess()
                })
                .catch(err => {
                    console.error(err.code)
                })
            } else if (location === 'Knollwood') {
                image = event.target.files[0]
                firebase.storage().ref(`/Images/${location}/${lotName}-${lotNumber}`)
                .put(image)
                .then(() => {
                    firebase.storage().ref(`/Images/${location}/${lotName}-${lotNumber}`)
                    .getDownloadURL()
                    .then(url => {
                        setImageUrl(url)
                        let lotUrl = {
                            imageUrl: url
                        }
                        let path = {
                            originalPath: `/Images/${location}/${section}-${lotNumber}`
                        }
                        firebase.firestore().collection('sold').doc(lotId)
                        .update(lotUrl)
                        .then(() => {
                            firebase.firestore().collection(location).doc(lotId)
                            .update(path)
                            .then(() => {
                                setOriginalPath(path.originalPath)
                            })
                        })
                    })
                })
                // handle putting image on background
                .then(() => {
                    uploadImageSuccess()
                })
                .catch(err => {
                    console.error(err.code)
                })
            } else if (location === 'Estate' || location === 'Other') {
                let randomNumber = Math.floor(Math.random() * 1000)
                image = event.target.files[0]
                firebase.storage().ref(`/Images/${location}/${otherLocation}-${randomNumber}`)
                .put(image)
                .then(() => {
                    firebase.storage().ref(`/Images/${location}/${otherLocation}-${randomNumber}`)
                    .getDownloadURL()
                    .then(url => {
                        setImageUrl(url)
                        setImageNumber(randomNumber)
                        let lotUrl = {
                            imageUrl: url
                        }
                        let imageNumber = {
                            imageNumber: randomNumber
                        }
                        let path = {
                            originalPath: `/Images/${location}/${section}-${lotNumber}`
                        }
                        firebase.firestore().collection('sold').doc(lotId)
                        .update(lotUrl)
                        .then(() => {
                            firebase.firestore().collection('sold').doc(lotId)
                            .update(imageNumber)
                        })
                        .then(() => {
                            firebase.firestore().collection(location).doc(lotId)
                            .update(path)
                            .then(() => {
                                setOriginalPath(path.originalPath)
                            })
                        })
                    })
                })
                // handle putting image on background
                .then(() => {
                    uploadImageSuccess()
                })
                .catch(err => {
                    console.error(err.code)
                })
            }
        }
        } catch (e) {
            console.error(e)
        }
        // uploadingImage()

        // let image
        // if (!lotIsSold) {
        //     if (location === 'Sharon' || location === 'Interfaith') {
        //         image = event.target.files[0]
        //         firebase.storage().ref(`/Images/${location}/${section}-${lotNumber}`)
        //         .put(image)
        //         .then(() => {
        //             firebase.storage().ref(`/Images/${location}/${section}-${lotNumber}`)
        //             .getDownloadURL()
        //             .then(url => {
        //                 setImageUrl(url)
        //                 let lotUrl = {
        //                     imageUrl: url
        //                 }
        //                 let path = {
        //                     originalPath: `/Images/${location}/${section}-${lotNumber}`
        //                 }
        //                 firebase.firestore().collection(location).doc(lotId)
        //                 .update(lotUrl)
        //                 .then(() => {
        //                     firebase.firestore().collection(location).doc(lotId)
        //                 .update(path)
        //                 .then(() => {
        //                     setOriginalPath(path.originalPath)
        //                 })
        //                 })
        //             })
        //         })
        //         .then(() => {
        //             uploadImageSuccess()
        //         })
        //         .catch(err => {
        //             console.error(err.code)
        //         })
        //     } else if (location === 'Knollwood') {
        //         image = event.target.files[0]
        //         firebase.storage().ref(`/Images/${location}/${lotName}-${lotNumber}`)
        //         .put(image)
        //         .then(() => {
        //             firebase.storage().ref(`/Images/${location}/${lotName}-${lotNumber}`)
        //             .getDownloadURL()
        //             .then(url => {
        //                 setImageUrl(url)
        //                 let lotUrl = {
        //                     imageUrl: url
        //                 }
        //                 let path = {
        //                     originalPath: `/Images/${location}/${lotName}-${lotNumber}`
        //                 }
        //                 firebase.firestore().collection(location).doc(lotId)
        //                 .update(lotUrl)
        //                 .then(() => {
        //                     firebase.firestore().collection(location).doc(lotId)
        //                 .update(path)
        //                 .then(() => {
        //                     setOriginalPath(path.originalPath)
        //                 })
        //                 })
        //             })
        //         })
        //         .then(() => {
        //             uploadImageSuccess()
        //         })
        //         .catch(err => {
        //             console.error(err.code)
        //         })
        //     } else if (location === 'Estate' || location === 'Other') {
        //         let randomNumber = Math.floor(Math.random() * 1000)
        //         image = event.target.files[0]
        //         firebase.storage().ref(`/Images/${location}/${otherLocation}-${randomNumber}`)
        //         .put(image)
        //         .then(() => {
        //             firebase.storage().ref(`/Images/${location}/${otherLocation}-${randomNumber}`)
        //             .getDownloadURL()
        //             .then(url => {
        //                 setImageUrl(url)
        //                 setImageNumber(randomNumber)
        //                 let lotUrl = {
        //                     imageUrl: url
        //                 }
        //                 let imageNumber = {
        //                     imageNumber: randomNumber
        //                 }
        //                 let path = {
        //                     originalPath: `/Images/${location}/${otherLocation}-${randomNumber}`
        //                 }
        //                 firebase.firestore().collection(location).doc(lotId)
        //                 .update(lotUrl)
        //                 .then(() => {
        //                     firebase.firestore().collection(location).doc(lotId)
        //                     .update(imageNumber)
        //                     .then(() => {
        //                         firebase.firestore().collection(location).doc(lotId)
        //                     .update(path)
        //                     .then(() => {
        //                         setOriginalPath(path.originalPath)
        //                     })
        //                     })
        //                 })
        //             })
        //         })
        //         .then(() => {
        //             uploadImageSuccess()
        //         })
        //         .catch(err => {
        //             console.error(err.code)
        //         })
        //     } else if (location === 'Shara') {
        //         image = event.target.files[0]
        //         firebase.storage().ref(`/Images/Shara/SharaImage`)
        //         .put(image)
        //         .then(() => {
        //             firebase.storage().ref(`/Images/Shara/SharaImage`)
        //             .getDownloadURL()
        //             .then(url => {
        //                 setImageUrl(url)
        //                 let lotUrl = {
        //                     imageUrl: url
        //                 }
        //                 let path = {
        //                     originalPath: `/Images/Shara/SharaImage`
        //                 }
        //                 firebase.firestore().collection(location).doc(lotId)
        //                 .update(lotUrl)
        //                 .then(() => {
        //                     firebase.firestore().collection(location).doc(lotId)
        //                 .update(path)
        //                 .then(() => {
        //                     setOriginalPath(path.originalPath)
        //                 })
        //                 })
        //             })
        //         })
        //         .then(() => {
        //             uploadImageSuccess()
        //         })
        //         .catch(err => {
        //             console.error(err.code)
        //         })
        //     }
        // } else if (lotIsSold) {
        //     if (location === 'Sharon' || location === 'Interfaith' || location === 'Boylston') {
        //         image = event.target.files[0]
        //         firebase.storage().ref(`/Images/${location}/${section}-${lotNumber}`)
        //         .put(image)
        //         .then(() => {
        //             firebase.storage().ref(`/Images/${location}/${section}-${lotNumber}`)
        //             .getDownloadURL()
        //             .then(url => {
        //                 setImageUrl(url)
        //                 let lotUrl = {
        //                     imageUrl: url
        //                 }
        //                 let path = {
        //                     originalPath: `/Images/${location}/${section}-${lotNumber}`
        //                 }
        //                 firebase.firestore().collection('sold').doc(lotId)
        //                 .update(lotUrl)
        //                 .then(() => {
        //                     firebase.firestore().collection(location).doc(lotId)
        //                     .update(path)
        //                     .then(() => {
        //                         setOriginalPath(path.originalPath)
        //                     })
        //                 })
        //             })
        //         })
        //         .then(() => {
        //             uploadImageSuccess()
        //         })
        //         .catch(err => {
        //             console.error(err.code)
        //         })
        //     } else if (location === 'Knollwood') {
        //         image = event.target.files[0]
        //         firebase.storage().ref(`/Images/${location}/${lotName}-${lotNumber}`)
        //         .put(image)
        //         .then(() => {
        //             firebase.storage().ref(`/Images/${location}/${lotName}-${lotNumber}`)
        //             .getDownloadURL()
        //             .then(url => {
        //                 setImageUrl(url)
        //                 let lotUrl = {
        //                     imageUrl: url
        //                 }
        //                 let path = {
        //                     originalPath: `/Images/${location}/${section}-${lotNumber}`
        //                 }
        //                 firebase.firestore().collection('sold').doc(lotId)
        //                 .update(lotUrl)
        //                 .then(() => {
        //                     firebase.firestore().collection(location).doc(lotId)
        //                     .update(path)
        //                     .then(() => {
        //                         setOriginalPath(path.originalPath)
        //                     })
        //                 })
        //             })
        //         })
        //         // handle putting image on background
        //         .then(() => {
        //             uploadImageSuccess()
        //         })
        //         .catch(err => {
        //             console.error(err.code)
        //         })
        //     } else if (location === 'Estate' || location === 'Other') {
        //         let randomNumber = Math.floor(Math.random() * 1000)
        //         image = event.target.files[0]
        //         firebase.storage().ref(`/Images/${location}/${otherLocation}-${randomNumber}`)
        //         .put(image)
        //         .then(() => {
        //             firebase.storage().ref(`/Images/${location}/${otherLocation}-${randomNumber}`)
        //             .getDownloadURL()
        //             .then(url => {
        //                 setImageUrl(url)
        //                 setImageNumber(randomNumber)
        //                 let lotUrl = {
        //                     imageUrl: url
        //                 }
        //                 let imageNumber = {
        //                     imageNumber: randomNumber
        //                 }
        //                 let path = {
        //                     originalPath: `/Images/${location}/${section}-${lotNumber}`
        //                 }
        //                 firebase.firestore().collection('sold').doc(lotId)
        //                 .update(lotUrl)
        //                 .then(() => {
        //                     firebase.firestore().collection('sold').doc(lotId)
        //                     .update(imageNumber)
        //                 })
        //                 .then(() => {
        //                     firebase.firestore().collection(location).doc(lotId)
        //                     .update(path)
        //                     .then(() => {
        //                         setOriginalPath(path.originalPath)
        //                     })
        //                 })
        //             })
        //         })
        //         // handle putting image on background
        //         .then(() => {
        //             uploadImageSuccess()
        //         })
        //         .catch(err => {
        //             console.error(err.code)
        //         })
        //     }
        // }
        
    }

    const iconStyle = {
        color: 'rgba(189, 31, 31, 0.712)',
        fontSize: '4vh',
        // paddingTop: '10px'   
    }
    
    imageUrl
    ? cardImageUrl = imageUrl
    : cardImageUrl = ('')
    imageUrl
    ? changingOpacity = 'rgba(135, 207, 235, 0.55)'
    : changingOpacity = 'rgba(135, 207, 235, 1)'

    const style2 = {
        background: 'rgba(255, 255, 255, 0)'
    }
    const style3 = {
        fontSize: '6vh',
        color: changingOpacity
    }

    updatingCard || currentlyDeleting || currentlySelling || uploadingLotImage || editingCard || lotIsSold
        ? deleteDisabledCheck = true
        : deleteDisabledCheck = false
    imageUrl
        ? deleteImageButton = ( 
            <div className='delete-image-d-admin'>
                    <IconButton disabled={deleteDisabledCheck}
                            onClick={() => {
                                deleteLotImage()
                            }} >
                        <HighlightOffTwoToneIcon style={iconStyle}/>
                    </IconButton>
            </div>
        )
        : deleteImageButton = ( '')

    return (
        <div className='admin-final-d-lot-card-picture-container'>
            <div className='admin-d-final-picture-holder'
                style={{backgroundImage: `url(${cardImageUrl})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
                }}
            >
                <div className='admin-d-image-delete-icon'>
                    {deleteImageButton}
                </div>
                <div className='admin-d-image-upload-icon'>
                    <Button
                        disableElevation={true}
                        style={style2}
                        disabled={deleteDisabledCheck}
                        variant="contained"
                        component="label"
                        color='primary'
                        onChange={handleImageUpload}>
                        <AddAPhotoTwoToneIcon color='secondary' style={style3}/>
                        <input type="file" style={{ display: "none" }} />
                    </Button>
                </div>
                <div className='admin-d-image-buffer'>
                </div>
            </div>
        </div>
    )
}