import React, {useContext} from 'react'

import {InventoryContext} from './../../../Contexts/InventoryContext'

export default function InventoryHeader() {
    let {
        location
    } = useContext(InventoryContext)

    if (location === 'Sharon' || location === 'Interfaith' || location === 'Boylston') {
        return (
            <div className='d-inventory-header-container'>
                <div className="d-inventory-header-item">
                    Section
                </div>
                <div className="d-inventory-header-item">
                    Lot Number
                </div>
                <div className="d-inventory-header-item">
                    Spaces
                </div>
                <div className="d-inventory-header-item">
                    Price
                </div>
            </div>
        )
    } else if (location === 'Knollwood') {
        return (
            <div className='d-inventory-header-container'>
                <div className="d-inventory-header-item">
                    Lot Name
                </div>
                <div className="d-inventory-header-item">
                    Lot Number
                </div>
                <div className="d-inventory-header-item">
                    Spaces
                </div>
                <div className="d-inventory-header-item">
                    Price
                </div>
            </div>
        )
    } else if (location === 'Estate' || location === 'Other') {
        return (
            <div className='d-inventory-header-container'>
                <div className="d-inventory-header-item">
                    Location
                </div>
                <div className="d-inventory-header-item">
                    Spaces
                </div>
                <div className="d-inventory-header-item">
                    Price
                </div>
            </div>
        )
    } else if (location === 'sold') {
        return (
            <div className='d-inventory-header-container'>
                <div className="d-inventory-header-sold-item">
                    Location
                </div>
                <div className="d-inventory-header-sold-item">
                    Section/Name
                </div>
                <div className="d-inventory-header-sold-item">
                    Lot Number
                </div>
                <div className="d-inventory-header-sold-item">
                    Spaces
                </div>
                {/* <div className="d-inventory-header-sold-item">
                    Price
                </div> */}
            </div>
        )
    } else if (location === 'Shara') {
        return (
            <div className='d-inventory-header-container'>
                <div className="d-inventory-header-item">
                    Spaces
                </div>
                <div className="d-inventory-header-item">
                    Price
                </div>
            </div>
        )
    }
}