import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { AdminContext } from './../../../../Contexts/AdminContext'

import firebase from 'firebase/app'
import 'firebase/firestore'

export default function AdminLotFilterLocation() {

    let {
        // adminVisibleLotList,
        loadingLocation,
        startLoadingLocation,
        stopLoadingLocation,
        setFilterLocation,
        filterLocation,
        clearAnchors,
        anchor,
        filterLocationMenu,
        setFilterLocationAnchor,
        clearNameFilter,
        addLotToVisibleList,
        lotIsNotSold
    } = useContext(AdminContext)

    let filterLocationCheck, disabledMenuCheck


    const checkFilterLocation = async (collection) => {
        try {
            startLoadingLocation()
            setFilterLocation(collection)
            clearNameFilter()
            if (collection !== 'sold') {
                lotIsNotSold()
            }
            if (collection === 'Sharon' || collection === 'Interfaith' || collection === 'Boylston') {
                firebase.firestore().collection(collection).orderBy('priority').orderBy('section').orderBy('lotNumber')
                .get()
                .then((snapshot) => {
                    snapshot.forEach(lot => {
                        let lotDetails = {
                            lotNumber: lot.data().lotNumber,
                            location: lot.data().location,
                            section: lot.data().section,
                            lotName: lot.data().lotName,
                            otherLocation: lot.data().otherLocation,
                            price: lot.data().price,
                            lotId: lot.id,
                            spaces: lot.data().spaces
                        }
                        addLotToVisibleList(lotDetails)
                    })
                })
                .then(() => {
                    setTimeout(() => {
                        stopLoadingLocation()
                    }, 700)
                })
                .catch(err => {
                    console.error(err.message)
                    console.error(err.code)
                    console.error(err)
                })
            } else if (collection === 'Knollwood') {
                firebase.firestore().collection(collection).orderBy('lotName').orderBy('lotNumber')
                .get()
                .then((snapshot) => {
                    snapshot.forEach(lot => {
                        let lotDetails = {
                            lotNumber: lot.data().lotNumber,
                            location: lot.data().location,
                            section: lot.data().section,
                            lotName: lot.data().lotName,
                            otherLocation: lot.data().otherLocation,
                            price: lot.data().price,
                            lotId: lot.id,
                            spaces: lot.data().spaces
                        }
                        addLotToVisibleList(lotDetails)
                    })
                })
                .then(() => {
                    setTimeout(() => {
                        stopLoadingLocation()
                    }, 700)
                })
                .catch(err => {
                    console.error(err.code)
                })
            } else if (collection === 'sold') {
                firebase.firestore().collection('sold').orderBy('location')
                .get()
                .then((snapshot) => {
                    snapshot.forEach(lot => {
                        let lotDetails = {
                            lotNumber: lot.data().lotNumber,
                            location: lot.data().location,
                            section: lot.data().section,
                            lotName: lot.data().lotName,
                            otherLocation: lot.data().otherLocation,
                            price: lot.data().price,
                            lotId: lot.id,
                            spaces: lot.data().spaces
                        }
                        addLotToVisibleList(lotDetails)
                    })
                })
                .then(() => {
                    setTimeout(() => {
                        stopLoadingLocation()
                    }, 700)
                })
                .catch(err => {
                    console.error(err.code)
                })
            } else {
                firebase.firestore().collection(collection)
                .get()
                .then((snapshot) => {
                    snapshot.forEach(lot => {
                        let lotDetails = {
                            lotNumber: lot.data().lotNumber,
                            location: lot.data().location,
                            section: lot.data().section,
                            lotName: lot.data().lotName,
                            otherLocation: lot.data().otherLocation,
                            price: lot.data().price,
                            lotId: lot.id,
                            spaces: lot.data().spaces
                        }
                        addLotToVisibleList(lotDetails)
                    })
                })
                .then(() => {
                    setTimeout(() => {
                        stopLoadingLocation()
                    }, 700)
                })
                .catch(err => {
                    console.error(err.code)
                })
            }
        } catch (e) {
            console.error(e)
            stopLoadingLocation()
        }
    }

    loadingLocation
        ? disabledMenuCheck = true
        : disabledMenuCheck = false

    filterLocation
        ? filterLocationCheck = filterLocation
        : filterLocationCheck = ( 'Locations' )

    const options = [
        'Sharon',
        'Interfaith',
        'Knollwood',
        'Boylston',
        'Shara',
        'Estate',
        'Other',
        'sold'
    ]

    return (
        <div className='context-lot-filter-location'>
            <Button
                disabled={disabledMenuCheck}
                aria-controls="location-menu" 
                aria-haspopup="true" 
                onClick={event => {
                    setFilterLocationAnchor(event.currentTarget)
                }}>
                    <div className='context-lot-filter-font'>
                        {filterLocationCheck}
                    </div>
            </Button>
            <Menu
                style={{ zIndex: 1000030}}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                id="filter-location-menu"
                anchorEl={anchor}
                keepMounted
                open={filterLocationMenu}
                onClose={() => {
                    clearAnchors()
                }}>
                {options.map((option, index) => <MenuItem key={index} onClick={() => checkFilterLocation(option)}><div className='context-lot-filter-location-item'>{option}</div></MenuItem>)}
            </Menu>
        </div>
    )
}