import React from 'react';
import './style/App.css';
import './StyleNew/Index.css';
import {
    BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

import About from './components/About/About'
import Home from './components/Home/Home'
import Contact from './components/Contact/Contact'
import Nav from './components/nav/Nav'

// import Login from './components/Admin/Login/Login'
import Admin from './components/Admin/Admin'

import Directors from './components/Directors/Directors'
import Gallery from './components/Gallery/Gallery'
import Footer from './components/Footer/Footer'
import Inventory from './components/Inventory/Inventory'

const theme = createMuiTheme({
    background: 'rgb(116, 181, 207)',
    palette: {
        primary: {
            main: 'rgb(255, 255, 255)',
            light: 'rgb(168, 217, 236)',
            dark: 'rgb(168, 217, 236)'
        },
        secondary: {
            main: 'rgb(97, 187, 223)',
            light: 'rgb(255, 255, 255)',
            dark: 'rgb(19, 88, 116)'
        },
        danger: {
            main: 'rgb(114, 25, 25)',
            light: 'rgb(235, 192, 192)',
            dark: 'rgb(44, 7, 7)'
        }
    }
})

export default class App extends React.Component {
    constructor() {
        super()

        this.state = {

        }
    }

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <Router>
                    <div className="App">
                        <Nav />
                        <Switch>
                            <Route exact path='/' component={Home} />
                            <Route path='/gallery' component={Gallery} />
                            <Route path='/inventory' component={Inventory}/>
                            <Route path='/contact' component={Contact} />
                            <Route path='/about' component={About} />
                            <Route path='/admin' component={Admin} />
                            <Route path='/directors' component={Directors} />
                        </Switch>
                        <Footer />
                    </div>
                </Router>
            </MuiThemeProvider>
        )
    }
}
