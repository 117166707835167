import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import { AdminContext } from '../../../../../Contexts/AdminContext'

import firebase from 'firebase/app'
import 'firebase/firestore'

export default function LotCardSoldButton() {

    let {
        originalPath,
        // imageNumber,
        // garden,
        lotId,
        priority,
        location,
        lotName,
        section,
        spaces,
        price,
        lotNumber,
        comments,

        otherLocation,

        updatingCard,
        editingCard,

        toggleSold,
        clearLotId,
        switchLotId,
        setNewLotId,

        currentlySelling,
        attemptToSell,
        lotIsSold,
        imageUrl,
        imageNumber
        // selling
    } = useContext(AdminContext)

    let checkBoxStatus, disableSellButtonCheck
    
    updatingCard || editingCard
        ? disableSellButtonCheck = true
        : disableSellButtonCheck = false

    currentlySelling
        ? checkBoxStatus = ( <CircularProgress color='primary' style={{fontSize: '5vh'}} /> )
        : checkBoxStatus = ( <Checkbox disabled={disableSellButtonCheck}  color="primary" style={{fontSize: '10vh'}} checked={lotIsSold}/> )

    function finishSelling() {
        setTimeout(() => {
            switchLotId()
            clearLotId()
            toggleSold()
        }, 1500)
    }


    function handleSoldChange() {
        attemptToSell()

        let today = new Date()
        let day = today.getDate().toString()
        let month = (today.getMonth() + 1).toString()
        let year = today.getFullYear().toString()
        let todaysDate = month.concat('-',day,'-',year)

        let fixedPath, fixedUrl, fixedNumber
            if (originalPath) {
                fixedPath = originalPath
            } else {
                fixedPath = ''
            }
            if (imageUrl) {
                fixedUrl = imageUrl
            } else {
                fixedUrl = ''
            }
            if (imageNumber) {
                fixedNumber = imageNumber
            } else {
                fixedNumber = ''
            }

        if (location === 'Sharon' || location === 'Interfaith' || location === 'Boylston') {
            if (!lotIsSold) {
                try {
                    console.log('attempting to sell')

                    // Z37YwO5U6I9WD2InPJZz
                    firebase.firestore().collection('sold')
                    .add({
                        originalPath: fixedPath,
                        imageUrl: fixedUrl,
                        priority: priority,
                        location: location,
                        section: section,
                        spaces: spaces,
                        price: Number(price),
                        lotNumber: lotNumber,
                        comments: comments,
                        lastEdit: todaysDate
                    })
                    .then(docRef => {
                        console.log(docRef.id)
                        setNewLotId(docRef.id)
                    })
                    .then(() => {
                        console.log('attempting to delete')
                        firebase.firestore().collection(location).doc(lotId)
                        .delete()
                        console.log('deleted')
                    })
                    .then(() => {
                        finishSelling()
                    })
                    .catch(err => {
                        console.error(err.code)
                    })
                } catch (error) {
                    console.error(error)
                }
            } else {
                firebase.firestore().collection(location)
                .add({
                    originalPath: fixedPath,
                    imageUrl: fixedUrl,
                    priority: priority,
                    location: location,
                    section: section,
                    spaces: spaces,
                    price: Number(price),
                    lotNumber: lotNumber,
                    comments: comments,
                    lastEdit: todaysDate
                })
                .then(docRef => {
                    setNewLotId(docRef.id)
                })
                .then(() => {
                    firebase.firestore().collection('sold').doc(lotId)
                    .delete()
                })
                .then(() => {
                    finishSelling()
                })
                .catch(err => {
                    console.error(err.code)
                })
            }
        } else if (location === 'Knollwood') {
            const gardenList = [
                'Cross',
                'Faith',
                'Gethsemane',
                'Last Supper',
                'Meditation',
                'Saviour',
                'Sunset',
                'Word',
                'Peace',
                'Remembrance'
            ]
            let gardenCheck 
                if (gardenList.includes(lotName)) {
                        gardenCheck = true
                } else {
                        gardenCheck = false
                }
            if (!lotIsSold) {
                firebase.firestore().collection('sold')
                .add({
                    originalPath: fixedPath,
                    imageUrl: fixedUrl,
                    garden: gardenCheck,
                    location: location,
                    lotName: lotName,
                    spaces: spaces,
                    price: Number(price),
                    lotNumber: lotNumber,
                    comments: comments,
                    lastEdit: todaysDate
                })
                .then(docRef => {
                    setNewLotId(docRef.id)
                })
                .then(() => {
                    firebase.firestore().collection(location).doc(lotId)
                    .delete()
                })
                .then(() => {
                    finishSelling()
                })
                .catch(err => {
                    console.error(err.code)
                })
            } else {
                firebase.firestore().collection('Knollwood')
                .add({
                    originalPath: fixedPath,
                    imageUrl: fixedUrl,
                    garden: gardenCheck,
                    location: location,
                    lotName: lotName,
                    spaces: spaces,
                    price: Number(price),
                    lotNumber: lotNumber,
                    comments: comments,
                    lastEdit: todaysDate
                })
                .then(docRef => {
                    setNewLotId(docRef.id)
                })
                .then(() => {
                    firebase.firestore().collection('sold').doc(lotId)
                    .delete()
                })
                .then(() => {
                    finishSelling()
                })
                .catch(err => {
                    console.error(err.code)
                })
            }
        } else if (location === 'Estate' || location === 'Other') {
            if (!lotIsSold) {
                firebase.firestore().collection('sold')
                .add({
                    originalPath: fixedPath,
                    imageNumber: fixedNumber,
                    imageUrl: fixedUrl,
                    otherLocation: otherLocation,
                    location: location,
                    spaces: spaces,
                    price: Number(price),
                    comments: comments,
                    lastEdit: todaysDate
                })
                .then(docRef => {
                    setNewLotId(docRef.id)
                })
                .then(() => {
                    firebase.firestore().collection(location).doc(lotId)
                    .delete()
                })
                .then(() => {
                    finishSelling()
                })
                .catch(err => {
                    console.error(err.code)
                })
            } else {
                firebase.firestore().collection(location)
                .add({
                    originalPath: fixedPath,
                    imageNumber: fixedNumber,
                    imageUrl: fixedUrl,
                    otherLocation: otherLocation,
                    location: location,
                    spaces: spaces,
                    price: Number(price),
                    comments: comments,
                    lastEdit: todaysDate
                })
                .then(docRef => {
                    setNewLotId(docRef.id)
                })
                .then(() => {
                    firebase.firestore().collection('sold').doc(lotId)
                    .delete()
                })
                .then(() => {
                    finishSelling()
                })
                .catch(err => {
                    console.error(err.code)
                })
            }
        }
    }
          
    return (    
        <Button
        disabled={disableSellButtonCheck}
        disableElevation={true}
            onClick={() => {
                handleSoldChange()
            }}
        >
        <div className='new-d-context-sold-button-container'>
            <div className='new-d-context-sold-header'>Sold:</div>
            {checkBoxStatus}
        </div>
            
        </Button>
    )
}