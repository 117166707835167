import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { AdminContext } from './../../../../Contexts/AdminContext'

export default function AdminLocationMenu() {
    let { 
        location,
        setLocation,
        anchor,
        locationMenu,
        setLocationAnchor,
        clearAnchors
    } = useContext(AdminContext)

    let locationHeader

    location
            ? locationHeader = ( <div className='new-admin-d-location-choice'>{location}</div> )
            : locationHeader = ( <div className='new-admin-d-location-choice'>Choose...</div> )

    const dropdownOptions = [
        'Sharon',
        'Interfaith',
        'Knollwood',
        'Estate',
        'Other',
        'Boylston'
    ]

    return (
        <div className="new-admin-d-add-lot-backdrop-location">
            <div className="new-admin-d-add-lot-backdrop-location-header">
                Location: 
            </div>
            <div className="new-admin-d-add-lot-backdrop-location-menu">
                <Button
                    aria-controls="location-menu" 
                    aria-haspopup="true" 
                    onClick={event => {
                        setLocationAnchor(event.currentTarget)
                    }}>
                    {locationHeader}
                </Button>
                <Menu
                    style={{ zIndex: 1000030 }}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    id="location-menu"
                    anchorEl={anchor}
                    keepMounted
                    open={locationMenu}
                    onClose={clearAnchors}
                >
                    {dropdownOptions.map((option, index) => <MenuItem key={index} onClick={() => {setLocation(option)}}>{option}</MenuItem>)}
                </Menu>
            </div>
        </div>
    )
}