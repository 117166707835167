import React, { useContext } from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import EstateOtherForm from './Components/EstateOtherForm'
import KnollwoodForm from './Components/KnollwoodForm'
import SharonForm from './Components/SharonForm'
import AddingNewLot from './Components/AddingNewLot'

import AdminLocationMenu from './Components/AdminLocationMenu'
import AdminCancelLotAddButton from './AdminCancelLotAddButton'

import { AdminContext } from './../../../Contexts/AdminContext'

import IndividualLotCard from './Components/IndividualLotCard'

export default function AdminAddLot() {

    let {
        addingLot,
        location,
        savingNewLot,
        lotCreated
    } = useContext(AdminContext)

    let addLotContent

    if (location === 'Knollwood') addLotContent = <KnollwoodForm />
    else if (['Sharon', 'Interfaith', 'Boylston'].includes(location)) addLotContent = <SharonForm />
    else if (location === 'Estate' || location === 'Other') addLotContent = <EstateOtherForm />
    else addLotContent = ''

    if (savingNewLot) return ( <AddingNewLot /> )
    else if (lotCreated) return ( <IndividualLotCard /> )
    else {

        const backdropstyle = { zIndex: 1000000, color: '#fff'}

        return (
            <Backdrop style={backdropstyle} open={addingLot}>
                <div className='new-admin-d-add-lot-backdrop'>
                    <AdminCancelLotAddButton />
                    <AdminLocationMenu />
                    <div className='new-admin-d-add-lot-backdrop-form-container'>
                        {addLotContent}
                    </div>
                </div>
            </Backdrop>
        )
    }
}