import React from 'react'

import { Link } from 'react-router-dom'

import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'

import InfoIcon from '@material-ui/icons/Info'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import PersonIcon from '@material-ui/icons/Person'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import ContactMailIcon from '@material-ui/icons/ContactMail'

export default function HomeTablet() {

    const contactStyle = {
        fontSize: '55px',
        padding: '0',
    }
    const iconStyle = {
        fontSize: '65px'
    }
    return (
        <div className='t-home-container'>
            <div className="t-home-scroll-container">
                <div className="t-home-brand">
                    The Lot Broker
                </div>
                <div className='t-home-contact-row'>
                    <div className="t-home-contact-name">
                        Gary Levine
                    </div>
                    <a href="tel:6176403143" className='t-link-fix'>
                        <div className="t-home-contact-row-item">
                            <PhoneIcon style={contactStyle} />
                        </div>
                    </a>
                    <a href="mailto:GLevine@TheLotBroker.com" className='t-link-fix'>
                        <div className="t-home-contact-row-item">
                            <EmailIcon style={contactStyle} />
                        </div>
                    </a>
                </div>
                <div className="t-home-statement">
                    Bringing Buyers and Sellers of Interment Spaces Together
                </div>
                
                <div className="t-home-info-top">
                    Lots available now at Sharon and
                    Knollwood Memorial Parks
                    <br/>
                    Up to 50% savings off current park prices
                </div>
                <div className="t-home-info-bottom">
                    The Lot Broker has been actively adding more lots weekly. 
                    We now have lots available in Sharon Memorial Park in over 50+ 
                    sections and counting. Lots are available in 1,2,3,4,6,8 and 12 spaces 
                    depending on the lot. Spaces start at only $2900, and most are 
                    priced at 50% or less than regular park prices.
                </div>
                <div className="t-home-info-bottom">
                We have 12+ sections available in Knollwood, including all (4) veteran sections. Spaces start at $1200 each
                </div>
                <div className="t-home-info-bottom">
                Please check out our other section listed under current Inventory for additional cemetery offerings!
                </div>
                <div className='t-home-actions-container'>
                    <Link to='/about' style={{textDecoration: 'none', color: 'rgb(42, 98, 124)'}}>
                        <div className="t-home-actions-item">
                            <div className="t-home-actions-icon">
                                <InfoIcon style={iconStyle}/>
                            </div>
                            <div className="t-home-actions-text">
                                About The
                                <br/>
                                Lot Broker
                            </div>
                        </div>
                    </Link>
                    <Link to='/gallery' style={{textDecoration: 'none', color: 'rgb(42, 98, 124)'}}>
                        <div className="t-home-actions-item">
                            <div className="t-home-actions-icon">
                                <PhotoLibraryIcon style={iconStyle}/>
                            </div>
                            <div className="t-home-actions-text">
                                View
                                <br/>
                                Galleries
                            </div>
                        </div>
                    </Link>
                    <Link to='/directors' style={{textDecoration: 'none', color: 'rgb(42, 98, 124)'}}>
                        <div className="t-home-actions-item">
                            <div className="t-home-actions-icon">
                                <PersonIcon style={iconStyle}/>
                            </div>
                            <div className="t-home-actions-text">
                                Funeral Directors
                            </div>
                        </div>
                    </Link>
                    <Link to='/inventory' style={{textDecoration: 'none', color: 'rgb(42, 98, 124)'}}>
                        <div className="t-home-actions-item">
                            <div className="t-home-actions-icon">
                                <FormatListBulletedIcon style={iconStyle}/>
                            </div>
                            <div className="t-home-actions-text">
                                View Current Inventory
                            </div>
                        </div>
                    </Link>
                    <Link to='/contact' style={{textDecoration: 'none', color: 'rgb(42, 98, 124)'}}>
                        <div className="t-home-actions-item">
                            <div className="t-home-actions-icon">
                                <ContactMailIcon style={iconStyle}/>
                            </div>
                            <div className="t-home-actions-text">
                                Contact The Lot Broker
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}