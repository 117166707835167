import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import CircularProgress from '@material-ui/core/CircularProgress'

import firebase from 'firebase/app'
import 'firebase/firestore'

import { InventoryContext } from './../../../Contexts/InventoryContext'

export default function ChooseLocation() {

    let {
        setLocationChosen,
        setInitialLocation,
        disableButtons,
        loading,
        addToVisibleLots
    } = useContext(InventoryContext)

    let loadingFeedback

    loading
        ? loadingFeedback = ( <CircularProgress color='secondary' size={130}/> )
        : loadingFeedback = ( '' )

    function setLocation(location) {
        setInitialLocation(location)

        if (location === 'Sharon' || location === 'Interfaith' || location === 'Boylston') {
            firebase.firestore().collection(location).orderBy('priority').orderBy('section').orderBy('lotNumber')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let lot = {
                        location: doc.data().location,
                        section: doc.data().section,
                        spaces: doc.data().spaces,
                        price: doc.data().price,
                        lotNumber: doc.data().lotNumber,
                        id: doc.id,
                    }
                    addToVisibleLots(lot)
                })
            })
            .then(() => {
                setTimeout(() => {
                    setLocationChosen()
                }, 100)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (location === 'Knollwood') {
            firebase.firestore().collection(location).orderBy('lotName').orderBy('lotNumber')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let lot = {
                        location: doc.data().location,
                        lotName: doc.data().lotName,
                        spaces: doc.data().spaces,
                        price: doc.data().price,
                        lotNumber: doc.data().lotNumber,
                        id: doc.id,
                    }
                    addToVisibleLots(lot)
                })
            })
            .then(() => {
                setTimeout(() => {
                    setLocationChosen()
                }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (location === 'Estate' || location === 'Other') {
            firebase.firestore().collection(location)
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let lot = {
                        location: doc.data().location,
                        otherLocation: doc.data().otherLocation,
                        spaces: doc.data().spaces,
                        price: doc.data().price,
                        id: doc.id,
                    }
                    addToVisibleLots(lot)
                })
            })
            .then(() => {
                setTimeout(() => {
                    setLocationChosen()
                }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (location === 'sold') {
            firebase.firestore().collection('sold').where('location', '==', 'Sharon').orderBy('priority').orderBy('section')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let lot = {
                        location: doc.data().location,
                        otherLocation: doc.data().otherLocation,
                        section: doc.data().section,
                        lotName: doc.data().lotName,
                        lotNumber: doc.data().lotNumber,
                        spaces: doc.data().spaces,
                        price: doc.data().price,
                        id: doc.id,
                    }
                    addToVisibleLots(lot)
                })
            })
            .then(() => {
                firebase.firestore().collection('sold').where('location', '==', 'Interfaith').orderBy('priority').orderBy('section')
                .get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        let lot = {
                            location: doc.data().location,
                            otherLocation: doc.data().otherLocation,
                            section: doc.data().section,
                            lotName: doc.data().lotName,
                            lotNumber: doc.data().lotNumber,
                            spaces: doc.data().spaces,
                            price: doc.data().price,
                            id: doc.id,
                        }
                        addToVisibleLots(lot)
                    })
                })
            })
            .then(() => {
                firebase.firestore().collection('sold').where('location', '==', 'Knollwood')
                .get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        let lot = {
                            location: doc.data().location,
                            otherLocation: doc.data().otherLocation,
                            section: doc.data().section,
                            lotName: doc.data().lotName,
                            lotNumber: doc.data().lotNumber,
                            spaces: doc.data().spaces,
                            price: doc.data().price,
                            id: doc.id,
                        }
                        addToVisibleLots(lot)
                    })
                })
            })
            .then(() => {
                setTimeout(() => {
                    setLocationChosen()
                }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (location === 'Shara') {
            firebase.firestore().collection('Shara')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let lot = {
                        location: doc.data().location,
                        spaces: doc.data().spaces,
                        price: doc.data().price,
                        id: doc.id,
                    }
                    addToVisibleLots(lot)
                })
            })
            .then(() => {
                setTimeout(() => {
                    setLocationChosen()
                }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        }
    }

    return (
        <div className='d-inventory-choose-location-container'>
            <div className='d-inventory-choose-location-header'>
                Select Location to View Current Inventory
            </div>
            <ButtonGroup
                variant="text"
                disabled={disableButtons}
            >
                <Button onClick={() => { setLocation('Sharon') }} >
                    <div className='d-inventory-choose-item'>
                        Sharon
                    </div>
                </Button>
                <Button onClick={() => { setLocation('Interfaith') }}>
                    <div className='d-inventory-choose-item'>
                        Sharon Interfaith
                    </div>
                </Button>
                <Button onClick={() => { setLocation('Knollwood') }}>
                    <div className='d-inventory-choose-item'>
                        Knollwood
                    </div>
                </Button>
                <Button onClick={() => { setLocation('Shara') }}>
                    <div className='d-inventory-choose-item'>
                        Shara Tfilo
                    </div>
                </Button>
            </ButtonGroup> 

            <ButtonGroup
                className='button-group-spacing'
                variant='text'
                disabled={disableButtons}>
                <Button onClick={() => { setLocation('Boylston') }}>
                    <div className='d-inventory-choose-item'>
                        Boylston Lodge
                    </div>
                </Button>
                <Button onClick={() => { setLocation('Estate') }}>
                    <div className='d-inventory-choose-item'>
                        Estates
                    </div>
                </Button>
                <Button onClick={() => { setLocation('Other') }}>
                    <div className='d-inventory-choose-item'>
                        Other
                    </div>
                </Button>
                <Button onClick={() => { setLocation('sold') }}>
                    <div className='d-inventory-choose-item'>
                        Sold
                    </div>
                </Button>
            </ButtonGroup>

            <div className='d-inventory-initial-spinner'>

            </div>
            {loadingFeedback}
        </div>
    )
}